define('ember-common/helpers/is-equal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.isEqual = isEqual;

  /**
   * Compare 2 values to be exactly the same
   * @method  isEqual
   * @param   {*}  left  First value for comparison
   * @param   {*}  right Second value for comparison
   * @returns {Boolean}       The result of whether they are equal
   * @private
   */

  function isEqual(_ref /*, hash*/) {
    var _ref2 = _slicedToArray(_ref, 2);

    var left = _ref2[0];
    var right = _ref2[1];

    return left === right;
  }

  /**
   * Boilerplate body for internal applications
   * @class is-equal
   * @namespace Helper
   * @extends Ember.Component
   */
  exports['default'] = _ember['default'].Helper.helper(isEqual);
});