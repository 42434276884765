define('ember-common/services/raven', ['exports', 'ember', 'ember-cli-sentry/services/raven'], function (exports, _ember, _emberCliSentryServicesRaven) {
	'use strict';

	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	var observer = _ember['default'].observer;

	exports['default'] = _emberCliSentryServicesRaven['default'].extend({
		token: inject.service(),

		setUserContexts: observer('token.tokenData', function () {
			var token = get(this, 'token.tokenData');
			window.Raven.setUserContext(token);
		}).on('init'),

		unhandledPromiseErrorMessage: 'unhandledPromiseNoMessage',

		captureException: function captureException() /* error */{
			this._super.apply(this, arguments);
		},

		captureMessage: function captureMessage() /* message */{
			return this._super.apply(this, arguments);
		},

		enableGlobalErrorCatching: function enableGlobalErrorCatching() {
			return this._super.apply(this, arguments);
		},

		ignoreError: function ignoreError(error) {
			switch (error.name) {
				case 'TransitionAborted':
					return true;
				default:
					return false;
			}
		},

		callRaven: function callRaven() /* methodName, ...optional */{
			return this._super.apply(this, arguments);
		}
	});
});