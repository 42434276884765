define('ember-common/mixins/required-group', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var get = _ember['default'].get;

	exports['default'] = _ember['default'].Mixin.create({
		requiredGroup: null,
		unauthorizedRoute: null,
		unauthorizedNotify: true,
		unauthorizedMessage: null,
		beforeModel: function beforeModel(transition) {
			this._super.apply(this, arguments);
			var group = get(this, 'requiredGroup');
			var route = get(this, 'unauthorizedRoute');
			var notify = get(this, 'unauthorizedNotify');
			var message = get(this, 'unauthorizedMessage');
			if (group && !this.token.belongsTo(group)) {
				if (route) {
					this.transitionTo(route);
				} else {
					transition.abort();
				}
				if (notify) {
					this.notifications.warning(message || 'Insufficient permission to access this section');
				}
			}
		}
	});
});