define('ember-common/components/ht-body', ['exports', 'ember', 'ember-get-config'], function (exports, _ember, _emberGetConfig) {
	'use strict';

	var APP = _emberGetConfig['default'].APP;
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	var inject = _ember['default'].inject;

	/**
  * Boilerplate body for internal applications
  * @class ht-body
  * @namespace Component
  * @extends Ember.Component
  */
	exports['default'] = Component.extend({
		tagName: 'div',
		currentSite: APP.name,
		session: inject.service(),
		feedbackSite: computed('property', function () {
			if (_emberGetConfig['default']['ember-common'] && _emberGetConfig['default']['ember-common'].ahaName) {
				return 'https://' + _emberGetConfig['default']['ember-common'].ahaName + '.ideas.aha.io/';
			}
			return null;
		}),
		actions: {
			navigate: function navigate(url) {
				window.open(url, '_blank');
			},
			authenticate: function authenticate() {
				this.sendAction(this.get('identification'), this.get('password'));
			}
		}
	});
});