define('ember-common/components/local-table', ['exports', 'ember', 'moment', 'ember-common/templates/components/local-table'], function (exports, _ember, _moment, _emberCommonTemplatesComponentsLocalTable) {
	'use strict';

	var _slicedToArray = (function () {
		function sliceIterator(arr, i) {
			var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;_e = err;
			} finally {
				try {
					if (!_n && _i['return']) _i['return']();
				} finally {
					if (_d) throw _e;
				}
			}return _arr;
		}return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError('Invalid attempt to destructure non-iterable instance');
			}
		};
	})();

	var keys = Object.keys;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var getWithDefault = _ember['default'].getWithDefault;
	var setProperties = _ember['default'].setProperties;
	var getProperties = _ember['default'].getProperties;
	var computed = _ember['default'].computed;
	var observer = _ember['default'].observer;
	var isNone = _ember['default'].isNone;
	var A = _ember['default'].A;
	var on = _ember['default'].on;
	var defineProperty = _ember['default'].defineProperty;
	var compare = _ember['default'].compare;
	var typeOf = _ember['default'].typeOf;
	var run = _ember['default'].run;
	var Component = _ember['default'].Component;
	var assert = _ember['default'].assert;
	var assign = _ember['default'].assign;
	var S = _ember['default'].String;
	var O = _ember['default'].Object;
	var jQ = _ember['default'].$;

	var NOT_SORTED = -1;

	var defaultMessages = {
		searchLabel: 'Search: ',
		searchPlaceholder: '',
		columnsTitle: 'Columns',
		columnsShowAll: 'Show All',
		columnsHideAll: 'Hide All',
		columnsRestoreDefaults: 'Restore Defaults',
		tableSummary: 'Showing %@ - %@ of %@',
		allColumnsAreHidden: 'All columns are hidden. Use the columns dropdown to show some of them',
		noDataToShow: 'No records to show'
	};

	var defaultIcons = {
		sortAsc: 'chevron up icon',
		sortDesc: 'chevron down icon',
		columnVisible: 'check circle outline icon',
		columnHidden: 'radio icon',
		navFirst: 'angle double left icon',
		navPrev: 'angle left icon',
		navNext: 'angle right icon',
		navLast: 'angle double right icon',
		caret: 'caret',
		expandRow: 'plus icon',
		collapseRow: 'minus icon'
	};

	var defaultCssClasses = {
		outerTableWrapper: 'models-table-wrapper',
		innerTableWrapper: 'inner-table-wrapper',
		table: 'ui celled striped selectable table',
		globalFilterWrapper: 'ui search pull-left',
		columnsDropdownWrapper: 'ui right floated dropdown labeled right icon basic button',
		columnsDropdownButtonWrapper: 'btn-group',
		columnsDropdown: 'dropdown-menu',
		theadCell: 'table-header',
		theadCellNoSorting: 'table-header-no-sorting',
		theadCellNoFiltering: 'table-header-no-filtering',
		tfooterWrapper: 'ui grid table-footer clearfix',
		footerSummary: 'table-summary',
		footerSummaryNumericPagination: 'three wide column',
		footerSummaryDefaultPagination: 'twelve wide column',
		pageSizeWrapper: 'two wide column',
		pageSizeSelectWrapper: 'pull-right',
		paginationWrapper: 'table-nav',
		paginationWrapperNumeric: 'seven wide column',
		paginationWrapperDefault: 'two wide column',
		buttonDefault: 'ui button',
		noDataCell: '',
		collapseRow: 'collapse-row',
		expandRow: 'expandRow'
	};

	/**
  * //TODO complete
  * @method  isSortedByDefault
  * @param   {object}          column [description]
  * @returns {Boolean}                [description]
  */
	function isSortedByDefault(column) {
		return column.sortPrecedence > NOT_SORTED;
	}

	/**
  * Default filter-function used in the filter by columns
  *
  * @param {string} cellValue	Value in the table cell
  * @param {string} filterString	Needed substring
  * @returns {boolean}	Return if default
  */
	function defaultFilter(cellValue, filterString) {
		return -1 !== cellValue.indexOf(filterString);
	}

	/**
  * Convert some string to the human readable one
  *
  * @param {string} name	Value to convert
  * @returns {string}	The formatted text
  */
	function propertyNameToTitle(name) {
		return S.capitalize(S.dasherize(name).replace(/\-/g, ' '));
	}

	/**
  * data -> filteredContent -> arrangedContent -> visibleContent
  *
  * @class local-table
  * @extends Ember.Component
  */
	exports['default'] = Component.extend({
		routing: _ember['default'].inject.service('-routing'),

		layout: _emberCommonTemplatesComponentsLocalTable['default'],

		/**
   * Number of records shown on one table-page (size of the <code>visibleContent</code>)
   *
   * @type number
   * @property pageSize
   * @default 10
   */
		pageSize: 10,

		/**
   * @type {number}
   * @property currentPageNumber
   * @default 1
   */
		currentPageNumber: 1,

		/**
   * @type {string[]}
   * @property sortProperties
   * @default []
   */
		sortProperties: A([]),

		/**
   * Determines if multi-columns sorting should be used
   *
   * @type {boolean}
   * @property multipleColumnsSorting
   * @default false
   */
		multipleColumnsSorting: true,

		/**
   * Determines if table footer should be shown on the page
   *
   * @type {boolean}
   * @property showTableFooter
   * @default true
   */
		showTableFooter: true,

		/**
   * Determines if numeric pagination should be used
   *
   * @type {boolean}
   * @property useNumericPagination
   * @default false
   */
		useNumericPagination: false,

		/**
   * Determines if columns-dropdown should be shown
   *
   * @type {boolean}
   * @property showColumnsDropdown
   * @default true
   */
		showColumnsDropdown: true,

		/**
   * Determines if filtering by columns should be available to the user
   *
   * @type {boolean}
   * @property useFilteringByColumns
   * @default true
   */
		useFilteringByColumns: true,

		/**
   * Filter string.
   * Pass object for a date range filtering type
   * {
   * 	type: 'dateRange',
   *	columnName: 'columnName',
   *	start: startDate, 
   *	end: endDate 
   * }
   * 
   * @type {string}
   * @property filterString
   * @default ''
   */
		filterString: '',

		/**
   * Determines if filtering (global and by column) should ignore case
   *
   * @type {boolean}
   * @property filteringIgnoreCase
   * @default false
   */
		filteringIgnoreCase: false,

		/**
   * Determines if filtering should be done by hidden columns
   * Notice: after changing this value filtering results will be updated only after filter options are changed
   *
   * @type {boolean}
   * @property doFilteringByHiddenColumns
   * @default true
   */
		doFilteringByHiddenColumns: true,

		/**
   * Determines if "Global filter"-field should be shown
   *
   * @type {boolean}
   * @property showGlobalFilter
   * @default true
   */
		showGlobalFilter: true,

		/**
   * Determines if focus should be on the "Global filter"-field on component render
   *
   * @type {boolean}
   * @property focusGlobalFilter
   * @default false
   */
		focusGlobalFilter: false,

		/**
   * Determines if <code>processedColumns</code> will be updated if <code>columns</code> are changed (<code>propertyName</code> and
   * <code>template</code> are observed)
   * <b>IMPORTANT</b> All filter, sort and visibility options will be dropped to the default values while updating
   *
   * @type {boolean}
   * @property columnsAreUpdateable
   * @default false
   */
		columnsAreUpdateable: false,

		/**
   * <code>columns</code> fields which are observed to update shown table-columns
   * It is used only if <code>columnsAreUpdateable</code> is <code>true</code>
   *
   * @type {string[]}
   * @property columnFieldsToCheckUpdate
   * @default ['propertyName', 'template']
   */
		columnFieldsToCheckUpdate: A(['propertyName', 'template']),

		/**
   * All table records
   *
   * @type {Ember.Object[]}
   * @property data
   * @default []
   */
		data: A([]),

		/**
   * Table columns
   *
   * @type {ModelsTable~ModelsTableColumn[]}
   * @property columns
   * @default []
   */
		columns: A([]),

		/**
   * @type {Ember.Object[]}
   * @property processedColumns
   * @default []
   */
		processedColumns: A([]),

		/**
   * @type {Object}
   * @property messages
   */
		messages: O.create({}),

		/**
   * @type {Object}
   * @property classes
   */
		classes: O.create({}),

		/**
   * @type {Object}
   * @property icons
   */
		icons: O.create({}),

		/**
   * List of the additional headers
   * Used to group columns
   *
   * @type {groupedHeader[][]}
   * @property groupedHeaders
   */
		groupedHeaders: A([]),

		/**
   * Template with First|Prev|Next|Last buttons
   *
   * @type {string}
   * @property simplePaginationTemplate
   * @default 'components/local-table/simple-pagination'
   */
		simplePaginationTemplate: 'components/local-table/simple-pagination',

		/**
   * Template with nav buttons
   *
   * @type {string}
   * @property numericPaginationTemplate
   * @default 'components/local-table/numeric-pagination'
   */
		numericPaginationTemplate: 'components/local-table/numeric-pagination',

		/**
   * Template with table footer
   *
   * @type {string}
   * @property tableFooterTemplate
   * @default 'components/local-table/table-footer'
   */
		tableFooterTemplate: 'components/local-table/table-footer',

		/**
   * Template for page size
   *
   * @type {string}
   * @property pageSizeTemplate
   * @default 'components/local-table/table-footer'
   */
		pageSizeTemplate: 'components/local-table/page-size',

		/**
   * Determines if page size should be shown
   *
   * @type {boolean}
   * @property showPageSize
   * @default true
   */
		showPageSize: true,

		/**
   * Template with global filter
   *
   * @type {string}
   * @property globalFilterTemplate
   * @default 'components/local-table/global-filter'
   */
		globalFilterTemplate: 'components/local-table/global-filter',

		/**
   * Template with columns dropdown
   *
   * @type {string}
   * @property columnsDropdownTemplate
   * @default 'components/local-table/columns-dropdown'
   */
		columnsDropdownTemplate: 'components/local-table/columns-dropdown',

		/**
   * Template with header row for column names
   *
   * @type {string}
   * @property headerRowSortingTemplate
   * @default 'components/local-table/header-row-sorting'
   */
		headerSortingRowTemplate: 'components/local-table/header-row-sorting',

		/**
   * Template for sorting icons
   *
   * @type {string}
   * @property headerSortingIconsTemplate
   * @default 'components/local-table/header-sorting-icons'
   */
		headerSortingIconsTemplate: 'components/local-table/header-sorting-icons',

		/**
   * Template with header row for column filters
   *
   * @type {string}
   * @property headerFilteringRowTemplate
   * @default 'components/local-table/header-row-filtering'
   */
		headerFilteringRowTemplate: 'components/local-table/header-row-filtering',

		/**
   * Template with header rows for columns grouping
   *
   * @type {string}
   * @property headerFilteringRowTemplate
   * @default 'components/local-table/header-rows-grouped'
   */
		headerGroupedRowsTemplate: 'components/local-table/header-rows-grouped',

		/**
   * Template for table's row
   *
   * @type {string}
   * @default 'components/local-table/row'
   * @property rowTemplate
   */
		rowTemplate: 'components/local-table/row',

		/**
   * Template for expanded row
   *
   * @type {string}
   * @default 'components/local-table/expanded-row'
   * @property expandedRowTemplate
   */
		expandedRowTemplate: 'components/local-table/expanded-row',

		/**
   * Indexes of the expanded rows
   * It's set to the initial value when current page or page size is changed
   *
   * @type {Array.Number}
   * @private
   * @property _expandedRowIndexes
   */
		_expandedRowIndexes: null,

		/**
   * true - allow to expand more than 1 row
   * false - only 1 row may be expanded in the same time
   *
   * @type {boolean}
   * @default false
   * @property multipleExpand
   */
		multipleExpand: false,

		/**
   * @type {Array.Number}
   * @private
   * @property _selectedRowIndexes
   */
		_selectedRowIndexes: null,

		/**
   * @type {Boolean}
   * @default false
   * @property multipleSelect
   */
		multipleSelect: false,

		/**
   * Action-name sent on user interaction
   *
   * @type {string}
   * @default 'displayDataChanged'
   * @property displayDataChangedAction
   */
		displayDataChangedAction: 'displayDataChanged',

		/**
   * Determines if action on user interaction should be sent
   *
   * @type {boolean}
   * @default false
   * @property sendDisplayDataChangedAction
   */
		sendDisplayDataChangedAction: false,

		/**
   * Action-name sent on change of visible columns
   *
   * @type {string}
   * @default 'columnsVisibilityChanged'
   * @property columnsVisibilityChangedAction
   */
		columnsVisibilityChangedAction: 'columnsVisibilityChanged',

		/**
   * Determines if action on change of visible columns should be sent
   *
   * @type {boolean}
   * @default false
   * @property sendColumnsVisibilityChangedAction
   */
		sendColumnsVisibilityChangedAction: false,

		/**
    * Determines date range picker options
    */
		dateRanges: {
			'Overdue': [(0, _moment['default'])(new Date('2016-01-01T00:00:00')), (0, _moment['default'])().add(-1, 'days').endOf('day')],
			'Due today': [(0, _moment['default'])().startOf('day'), (0, _moment['default'])().endOf('day')]
		},
		opens: 'left',
		cancelLabel: 'All',
		applyClass: 'hidden',
		hideFieldValue: true,

		/**
   * List of the currently visible columns
   *
   * @type {Ember.Object[]}
   * @property visibleProcessedColumns
   */
		visibleProcessedColumns: computed.filterBy('processedColumns', 'isVisible', true),

		/**
   * True if all processedColumns are hidden by <code>isHidden</code>
   *
   * @type {boolean}
   * @property allColumnsAreHidden
   */
		allColumnsAreHidden: computed('processedColumns.@each.isHidden', function () {
			var processedColumns = get(this, 'processedColumns');
			return processedColumns.length > 0 && processedColumns.isEvery('isHidden', true);
		}),

		/**
   * Number of pages
   *
   * @type {number}
   * @property pagesCount
   */
		pagesCount: computed('arrangedContent.[]', 'pageSize', function () {
			var pagesCount = get(this, 'arrangedContent.length') / get(this, 'pageSize');
			return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
		}),

		/**
   * List of links to the page
   * Used if <code>useNumericPagination</code> is true
   * @typedef {object} visiblePageNumber
   * @property {boolean} isLink
   * @property {boolean} isActive
   * @property {string} label
   *
   * @type {Array.Object}
   * @property visiblePageNumbers
   */
		visiblePageNumbers: computed('arrangedContentLength', 'pagesCount', 'currentPageNumber', function () {
			var _getProperties = getProperties(this, 'pagesCount', 'currentPageNumber');

			var pagesCount = _getProperties.pagesCount;
			var currentPageNumber = _getProperties.currentPageNumber;

			var notLinkLabel = '...';
			var groups = []; // array of 8 numbers
			var labels = A([]);
			groups[0] = 1;
			groups[1] = Math.min(1, pagesCount);
			groups[6] = Math.max(1, pagesCount);
			groups[7] = pagesCount;
			groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
			groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
			groups[2] = Math.floor((groups[1] + groups[3]) / 2);
			groups[5] = Math.floor((groups[4] + groups[6]) / 2);

			for (var n = groups[0]; n <= groups[1]; n++) {
				labels[n] = n;
			}
			var userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;
			if (userGroup2) {
				labels[groups[2]] = notLinkLabel;
			}
			for (var i = groups[3]; i <= groups[4]; i++) {
				labels[i] = i;
			}
			var userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;
			if (userGroup5) {
				labels[groups[5]] = notLinkLabel;
			}
			for (var i = groups[6]; i <= groups[7]; i++) {
				labels[i] = i;
			}
			return A(labels.compact().map(function (label) {
				return {
					label: label,
					isLink: label !== notLinkLabel,
					isActive: label === currentPageNumber };
			}));
		}),

		/**
   * Are buttons "Back" and "First" enabled
   *
   * @type {boolean}
   * @property gotoBackEnabled
   * @private
   */
		gotoBackEnabled: computed.gt('currentPageNumber', 1),

		/**
   * Are buttons "Next" and "Last" enabled
   *
   * @type {boolean}
   * @property gotoForwardEnabled
   * @private
   */
		gotoForwardEnabled: computed('currentPageNumber', 'pagesCount', function () {
			return get(this, 'currentPageNumber') < get(this, 'pagesCount');
		}),

		/**
   * The filtered content, before sorting
   * @type {Ember.Object[]}
   * @property filteredContent
   * @private
   */
		filteredContent: computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filterString', 'processedColumns.@each.filterOperator', function () {
			var _getProperties2 = getProperties(this, 'processedColumns', 'data', 'useFilteringByColumns', 'filteringIgnoreCase', 'doFilteringByHiddenColumns');

			var processedColumns = _getProperties2.processedColumns;
			var data = _getProperties2.data;
			var useFilteringByColumns = _getProperties2.useFilteringByColumns;
			var filteringIgnoreCase = _getProperties2.filteringIgnoreCase;
			var doFilteringByHiddenColumns = _getProperties2.doFilteringByHiddenColumns;

			var filterString = get(this, 'filterString');

			if (!data) {
				return A([]);
			}

			var _processedColumns = processedColumns;
			if (!doFilteringByHiddenColumns) {
				_processedColumns = _processedColumns.filterBy('isHidden', false);
			}

			// global search
			var globalSearch = data.filter(function (row) {
				return _processedColumns.length ? _processedColumns.any(function (c) {
					var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
					if (filterFor) {
						var cellValue = '' + get(row, filterFor);
						if (filteringIgnoreCase) {
							cellValue = cellValue.toLowerCase();
							filterString = filterString.toLowerCase();
						}
						return -1 !== cellValue.indexOf(filterString);
					}
					return false;
				}) : true;
			});

			if (!useFilteringByColumns) {
				return A(globalSearch);
			}

			// search by each column
			return A(globalSearch.filter(function (row) {
				return _processedColumns.length ? _processedColumns.every(function (c) {
					var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
					if (filterFor) {
						var cellValue = '' + get(row, filterFor);
						if (get(c, 'useFilter')) {
							filterString = get(c, 'filterString');
							if (get(c, 'filterWithSelect')) {
								if ('' === filterString) {
									return true;
								}
								return 0 === compare(cellValue, filterString);
							} else {
								if (filteringIgnoreCase) {
									cellValue = cellValue.toLowerCase();
									filterString = filterString.toLowerCase();
								}
								return c.filterFunction(cellValue, filterString, row);
							}
						}
						return true;
					}
					return true;
				}) : true;
			}));
		}),

		/**
   * @type {Ember.Object[]}
   * @property arrangedContent
   */
		arrangedContent: computed('filteredContent.[]', 'sortProperties.[]', function () {
			var filteredContent = get(this, 'filteredContent');
			var sortProperties = get(this, 'sortProperties').map(function (p) {
				var _p$split = p.split(':');

				var _p$split2 = _slicedToArray(_p$split, 2);

				var prop = _p$split2[0];
				var direction = _p$split2[1];

				direction = direction || 'asc';

				return [prop, direction];
			});

			var _filteredContent = filteredContent.slice();
			return sortProperties.length ? A(_filteredContent.sort(function (row1, row2) {
				for (var i = 0; i < sortProperties.length; i++) {
					var _sortProperties$i = _slicedToArray(sortProperties[i], 2);

					var prop = _sortProperties$i[0];
					var direction = _sortProperties$i[1];

					var result = compare(get(row1, prop), get(row2, prop));
					if (result !== 0) {
						return direction === 'desc' ? -1 * result : result;
					}
				}

				return 0;
			})) : _filteredContent;
		}),

		/**
   * Content of the current table page
   *
   * @type {Ember.Object[]}
   * @property visibleContent
   */
		visibleContent: computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
			var _getProperties3 = getProperties(this, 'arrangedContent', 'pageSize', 'currentPageNumber');

			var arrangedContent = _getProperties3.arrangedContent;
			var pageSize = _getProperties3.pageSize;
			var currentPageNumber = _getProperties3.currentPageNumber;

			var startIndex = pageSize * (currentPageNumber - 1);
			if (get(arrangedContent, 'length') < pageSize) {
				return arrangedContent;
			}
			return A(arrangedContent.slice(startIndex, startIndex + pageSize));
		}),

		/**
   * Real table summary
   *
   * @type {string}
   * @property summary
   */
		summary: computed('firstIndex', 'lastIndex', 'arrangedContentLength', 'messages.tableSummary', function () {
			var _getProperties4 = getProperties(this, 'arrangedContentLength', 'firstIndex', 'lastIndex');

			var arrangedContentLength = _getProperties4.arrangedContentLength;
			var firstIndex = _getProperties4.firstIndex;
			var lastIndex = _getProperties4.lastIndex;

			var summary = '';
			var summarySplit = get(this, 'messages.tableSummary').split('%@');
			var values = [firstIndex, lastIndex, arrangedContentLength];
			for (var i = 0; i < summarySplit.length && i < 3; i++) {
				summary += '' + summarySplit[i] + values[i];
			}
			return summary;
		}),

		/**
   * Is user on the last page
   *
   * @type {boolean}
   * @property isLastPage
   */
		isLastPage: computed.not('gotoForwardEnabled'),

		/**
   * Alias to <code>arrangedContent.length</code>
   *
   * @type {number}
   * @property arrangedContentLength
   */
		arrangedContentLength: computed.alias('arrangedContent.length'),

		/**
   * Index of the first currently shown record
   *
   * @type {number}
   * @property firstIndex
   */
		firstIndex: computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
			var _getProperties5 = getProperties(this, 'currentPageNumber', 'pageSize', 'arrangedContentLength');

			var currentPageNumber = _getProperties5.currentPageNumber;
			var pageSize = _getProperties5.pageSize;
			var arrangedContentLength = _getProperties5.arrangedContentLength;

			return 0 === arrangedContentLength ? 0 : pageSize * (currentPageNumber - 1) + 1;
		}),

		/**
   * Index of the last shown record
   *
   * @type {number}
   * @property lastIndex
   */
		lastIndex: computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
			var _getProperties6 = getProperties(this, 'currentPageNumber', 'pageSize', 'isLastPage', 'arrangedContentLength');

			var currentPageNumber = _getProperties6.currentPageNumber;
			var pageSize = _getProperties6.pageSize;
			var isLastPage = _getProperties6.isLastPage;
			var arrangedContentLength = _getProperties6.arrangedContentLength;

			return isLastPage ? arrangedContentLength : currentPageNumber * pageSize;
		}),

		/**
   * List of possible <code>pageSize</code> values
   * Used to change size of <code>visibleContent</code>
   *
   * @type {Array.Number}
   * @default [10, 25, 50]
   * @property pageSizeValues
   */
		pageSizeValues: A([10, 25, 50]),

		/**
   * Show first page if for some reasons there is no content for current page, but table data exists
   *
   * @method visibleContentObserver
   */
		visibleContentObserver: function visibleContentObserver() {
			run.once(this, this.visibleContentObserverOnce);
		},

		visibleContentObserverOnce: function visibleContentObserverOnce() {
			var visibleContentLength = get(this, 'visibleContent.length');
			var dataLength = get(this, 'data.length');
			var currentPageNumber = get(this, 'currentPageNumber');
			if (!visibleContentLength && dataLength && currentPageNumber !== 1) {
				set(this, 'currentPageNumber', 1);
			}
		},

		/**
   * @method contentChangedAfterPolling
   */
		contentChangedAfterPolling: function contentChangedAfterPolling() {
			run.once(this, this.contentChangedAfterPollingOnce);
		},

		contentChangedAfterPollingOnce: function contentChangedAfterPollingOnce() {
			get(this, 'filteredContent');
			this.notifyPropertyChange('filteredContent');
		},

		/**
   * Component init
   * Set visibility and filtering attributes for each column
   * Update messages used by table with user-provided messages (@see messages)
   * Update icons used by table with user-provided icons (@see icons)
   * Update classes used by table with user-provided css-classes (@see classes)
   *
   * @method setup
   * @property setup
   */
		setup: on('init', function () {
			var _this = this;

			this._setupSelectedRows();
			this._setupExpandedRows();
			this._setupColumns();
			this._setupMessages();
			this._setupIcons();
			this._setupClasses();
			var columnsAreUpdateable = get(this, 'columnsAreUpdateable');
			if (columnsAreUpdateable) {
				var columnFieldsToCheckUpdate = get(this, 'columnFieldsToCheckUpdate');
				assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === typeOf(columnFieldsToCheckUpdate));
				columnFieldsToCheckUpdate.forEach(function (propertyName) {
					return _this.addObserver('columns.@each.' + propertyName, _this, _this._setupColumnsOnce);
				});
			}
			this.addObserver('visibleContent.length', this, this.visibleContentObserver);
		}),

		/**
   * Recalculate processedColumns when the columns attr changes
   */
		updateColumns: on('didReceiveAttrs', function () {
			if (get(this, 'columnsAreUpdateable')) {
				this._setupColumns();
			}
		}),

		/**
   * Focus on "Global filter" on component render and initialize dropdowns
   *
   * @method focus
   * @property focus
   */
		focus: on('didInsertElement', function () {
			if (get(this, 'showGlobalFilter') && get(this, 'focusGlobalFilter')) {
				jQ('.filterString').focus();
			}

			$('.persistent-text-dropdown').dropdown({
				keepOnScreen: false,
				action: 'nothing'
			});
		}),

		_setupExpandedRows: function _setupExpandedRows() {
			set(this, '_expandedRowIndexes', A([]));
		},

		_setupSelectedRows: function _setupSelectedRows() {
			set(this, '_selectedRowIndexes', A([]));
		},

		/**
   * Wrapper for <code>_setupColumns</code> to call it only once when observer is fired
   *
   * @method _setupColumnsOnce
   * @private
   */
		_setupColumnsOnce: function _setupColumnsOnce() {
			run.once(this, this._setupColumns);
		},

		/**
   * Create new properties for <code>columns</code> (filterString, useFilter, isVisible, defaultVisible)
   *
   * @method _setupColumns
   * @private
   */
		_setupColumns: function _setupColumns() {
			var _this2 = this;

			var self = this;

			var nColumns = A(get(this, 'columns').map(function (column) {
				var filterFunction = get(column, 'filterFunction');
				filterFunction = 'function' === typeOf(filterFunction) ? filterFunction : defaultFilter;

				var c = O.create(JSON.parse(JSON.stringify(column)));
				var propertyName = get(c, 'propertyName');
				var sortedBy = get(c, 'sortedBy');
				var filteredBy = get(c, 'filteredBy');
				var type = get(c, 'dataType') || 'string';

				if (!get(c, 'dataType')) {
					Object.keys(get(_this2, 'data.type.attributes._values')).forEach(function (x) {
						var pair = get(_this2, 'data.type.attributes._values')[x];
						if (pair.name === propertyName) {
							type = pair.type ? pair.type.toLowerCase() : 'string';
						}
					});
				}

				defineProperty(c, 'cssPropertyName', computed('propertyName', function () {
					return get(this, 'propertyName').replace(/\./g, '-');
				}));

				// let conditional = this.get('conditionals').find((x) => x.text === get(c, 'filterOperator') || x.value === get(c, 'filterOperator'));
				// let conditional = null;
				setProperties(c, {
					type: type,
					filterString: get(c, 'filterString') || '',
					selectFilterValueProperty: get(c, 'selectFilterValueProperty'),
					// filterOperator: conditional ? conditional.text : this.get('conditionals')[0].text,
					useFilter: !isNone(filteredBy || propertyName) && !get(c, 'disableFiltering'),
					useSorting: !isNone(sortedBy || propertyName) && !get(c, 'disableSorting')
				});

				set(c, 'filterFunction', filterFunction);

				if (isNone(get(c, 'mayBeHidden'))) {
					set(c, 'mayBeHidden', true);
				}

				var sortDirection = column.sortDirection;
				var sortPrecedence = column.sortPrecedence;

				var hasSortPrecedence = !isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
				var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
				var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

				defineProperty(c, 'isVisible', computed.not('isHidden'));
				defineProperty(c, 'sortAsc', computed.equal('sorting', 'asc'));
				defineProperty(c, 'sortDesc', computed.equal('sorting', 'desc'));

				setProperties(c, {
					defaultVisible: !get(c, 'isHidden'),
					sorting: defaultSorting,
					sortPrecedence: defaultSortPrecedence
				});

				// let type = this.get('dataTypes').find((x) => x.name === column.propertyName);
				// type = type ? type.type : 'string';

				if (get(c, 'filterWithSelect') && get(c, 'useFilter')) {
					var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
					if (predefinedFilterOptions && predefinedFilterOptions.length && (get(column, 'filterSelectDisplayProperty') && '' !== predefinedFilterOptions[0][get(column, 'filterSelectDisplayProperty')] || '' !== predefinedFilterOptions[0])) {
						predefinedFilterOptions = [''].concat(predefinedFilterOptions);
					}
					var usePredefinedFilterOptions = 'array' === typeOf(predefinedFilterOptions);
					set(c, 'filterOptions', usePredefinedFilterOptions ? predefinedFilterOptions : []);
					if (!usePredefinedFilterOptions && propertyName) {
						self.addObserver('data.@each.' + propertyName, self, self._updateFiltersWithSelect);
					}
				}
				return c;
			}));
			nColumns.filterBy('propertyName').forEach(function (column) {
				var propertyName = get(column, 'propertyName');
				if (isNone(get(column, 'title'))) {
					set(column, 'title', propertyNameToTitle(propertyName));
				}
			});
			set(this, 'processedColumns', nColumns);
			this._updateFiltersWithSelect();

			// Apply initial sorting
			set(this, 'sortProperties', A());
			var filteredOrderedColumns = nColumns.sortBy('sortPrecedence').filter(function (col) {
				return isSortedByDefault(col);
			});
			filteredOrderedColumns.forEach(function (column) {
				self.send('sort', column);
				var defaultSortedBy = column.sortedBy || column.propertyName;
				var sortingArgs = [column, defaultSortedBy, column.sortDirection.toLowerCase()];
				if (get(_this2, 'multipleColumnsSorting')) {
					_this2._multiColumnsSorting.apply(_this2, sortingArgs);
				} else {
					_this2._singleColumnSorting.apply(_this2, sortingArgs);
				}
			});
		},

		/**
   * Update messages used by widget with custom values provided by user in the <code>customMessages</code>
   *
   * @method _setupMessages
   * @private
   * @property _setupMessages
   */
		_setupMessages: observer('customMessages', function () {
			var customIcons = getWithDefault(this, 'customMessages', {});
			var newMessages = {};
			assign(newMessages, defaultMessages, customIcons);
			set(this, 'messages', O.create(newMessages));
		}),

		/**
   * Update icons-classes used by widget with custom values provided by user in the <code>customIcons</code>
   *
   * @method _setupIcons
   * @private
   */
		_setupIcons: function _setupIcons() {
			var customIcons = getWithDefault(this, 'customIcons', {});
			var newIcons = {};
			assign(newIcons, defaultIcons, customIcons);
			set(this, 'icons', O.create(newIcons));
		},

		/**
   * Update css-classes used by widget with custom values provided by user in the <code>customClasses</code>
   *
   * @method _setupClasses
   * @private
   */
		_setupClasses: function _setupClasses() {
			var customClasses = getWithDefault(this, 'customClasses', {});
			var newClasses = {};
			assign(newClasses, defaultCssClasses, customClasses);
			set(this, 'classes', O.create(newClasses));
		},

		/**
   * Updates <code>filterOptions</code> for columns which use <code>filterWithSelect</code>
   * and don't have <code>predefinedFilterOptions</code>
   * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
   * where data is component's <code>data</code>
   * If preselected option doesn't exist after <code>filterOptions</code> update,
   * <code>filterString</code> is reverted to empty string (basically, filtering for this column is dropped)
   *
   * @private
   * @method _updateFiltersWithSelect
   */
		_updateFiltersWithSelect: function _updateFiltersWithSelect() {
			var processedColumns = get(this, 'processedColumns');
			var data = get(this, 'data');
			processedColumns.forEach(function (column) {
				var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
				var filterWithSelect = get(column, 'filterWithSelect');
				if (filterWithSelect && 'array' !== typeOf(predefinedFilterOptions)) {
					var propertyName = get(column, 'propertyName');
					var filterOptions = A([''].concat(A(data.mapBy(propertyName)).compact())).uniq();
					var filterString = get(column, 'filterString');
					if (-1 === filterOptions.indexOf(filterString)) {
						set(column, 'filterString', '');
					}
					set(column, 'filterOptions', filterOptions);
				}
			});
		},

		/**
   * Set <code>sortProperties</code> when single-column sorting is used
   *
   * @param {object} column	The column to work on
   * @param {string} sortedBy	Property to sort by
   * @param {string} newSorting 'asc|desc|none'
   * @method _singleColumnSorting
   * @private
   */
		_singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
			get(this, 'processedColumns').setEach('sorting', 'none');
			set(column, 'sorting', newSorting);
			set(this, 'sortProperties', 'none' === newSorting ? [] : [sortedBy + ':' + newSorting]);
		},

		/**
   * Set <code>sortProperties</code> when multi-columns sorting is used
   *
   * @param {object} column	The column to work on
   * @param {string} sortedBy	Property to sort by
   * @param {string} newSorting 'asc|desc|none'
   * @method _multiColumnsSorting
   * @private
   */
		_multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
			set(column, 'sorting', newSorting);
			var sortProperties = get(this, 'sortProperties');
			var sortPropertiesMap = {};
			sortProperties.forEach(function (p) {
				var _p$split3 = p.split(':');

				var _p$split32 = _slicedToArray(_p$split3, 2);

				var propertyName = _p$split32[0];
				var order = _p$split32[1];

				sortPropertiesMap[propertyName] = order;
			});
			delete sortPropertiesMap[sortedBy];

			var newSortProperties = A([]);
			keys(sortPropertiesMap).forEach(function (propertyName) {
				if (propertyName !== sortedBy) {
					newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
				}
			});
			if ('none' !== newSorting) {
				newSortProperties.pushObject(sortedBy + ':' + newSorting);
			}
			set(this, 'sortProperties', newSortProperties);
		},

		/**
   * send <code>displayDataChangedAction</code>-action when user does sort of filter
   * action is sent only if <code>sendDisplayDataChangedAction</code> is true (default false)
   *
   * @method userInteractionObserver
   */
		userInteractionObserver: function userInteractionObserver() {
			var _this3 = this;

			if (get(this, 'sendDisplayDataChangedAction')) {
				(function () {
					var columns = get(_this3, 'processedColumns');
					var settings = O.create({
						sort: get(_this3, 'sortProperties'),
						currentPageNumber: get(_this3, 'currentPageNumber'),
						pageSize: get(_this3, 'pageSize'),
						filterString: get(_this3, 'filterString'),
						filteredContent: get(_this3, 'filteredContent'),
						selectedRowIndexes: get(_this3, '_selectedRowIndexes'),
						expandedRowIndexes: get(_this3, '_expandedRowIndexes'),
						columnFilters: {}
					});
					columns.forEach(function (column) {
						if (get(column, 'filterString')) {
							settings.columnFilters[get(column, 'propertyName')] = get(column, 'filterString');
						}
					});
					_this3.sendAction('displayDataChangedAction', settings);
				})();
			}
		},

		/**
   * send <code>columnsVisibilityChangedAction</code>-action when user changes which columns are visible
   * action is sent only if <code>sendColumnsVisibilityChangedAction</code> is true (default false)
   */
		_sendColumnsVisibilityChangedAction: function _sendColumnsVisibilityChangedAction() {
			if (get(this, 'sendColumnsVisibilityChangedAction')) {
				var columns = get(this, 'processedColumns');
				var columnsVisibility = columns.map(function (column) {
					var options = getProperties(column, 'isHidden', 'mayBeHidden', 'propertyName');
					options.isHidden = !!options.isHidden;
					return options;
				});
				this.sendAction('columnsVisibilityChangedAction', columnsVisibility);
			}
		},

		/**
   * Force <code>arrangedContent</code> to be updated when <code>sortProperties</code> is changed
   * Currently "normal" <code>Em.computed.sort</code> has issue when sort properties is empty
   *
   * @method forceUpdateArrangedContent
   * @property forseUpdateArrangedContent
   * @private
   */
		forceUpdateArrangedContent: observer('filteredContent.[]', 'sortProperties.[]', function () {
			this.notifyPropertyChange('arrangedContent');
		}),

		/**
   * Handler for global filter and filter by each column
   */
		filteringApplied: observer('filterString', 'processedColumns.@each.filterString', function () {
			set(this, 'currentPageNumber', 1);
			this.userInteractionObserver();
		}),

		paginationApplied: observer('pageSize', function () {
			set(this, 'currentPageNumber', 1);
			this.userInteractionObserver();
		}),

		collapseRow: observer('currentPageNumber', 'pageSize', function () {
			set(this, '_expandedRowIndexes', A([]));
		}),

		actions: {
			sendAction: function sendAction() {
				this.sendAction.apply(this, arguments);
			},

			toggleHidden: function toggleHidden(column) {
				if (get(column, 'mayBeHidden')) {
					column.toggleProperty('isHidden');
					this._sendColumnsVisibilityChangedAction();
				}
			},

			showAllColumns: function showAllColumns() {
				get(this, 'processedColumns').setEach('isHidden', false);
				this._sendColumnsVisibilityChangedAction();
			},

			hideAllColumns: function hideAllColumns() {
				get(this, 'processedColumns').filter(function (x) {
					return x.get('mayBeHidden');
				}).setEach('isHidden', true);
				this._sendColumnsVisibilityChangedAction();
			},

			restoreDefaultVisibility: function restoreDefaultVisibility() {
				var _this4 = this;

				get(this, 'processedColumns').forEach(function (c) {
					set(c, 'isHidden', !get(c, 'defaultVisible'));
					_this4._sendColumnsVisibilityChangedAction();
				});
			},

			gotoFirst: function gotoFirst() {
				if (!get(this, 'gotoBackEnabled')) {
					return;
				}
				set(this, 'currentPageNumber', 1);
				this.userInteractionObserver();
			},

			gotoPrev: function gotoPrev() {
				if (!get(this, 'gotoBackEnabled')) {
					return;
				}
				if (get(this, 'currentPageNumber') > 1) {
					this.decrementProperty('currentPageNumber');
					this.userInteractionObserver();
				}
			},

			gotoNext: function gotoNext() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var currentPageNumber = get(this, 'currentPageNumber');
				var pageSize = get(this, 'pageSize');
				var arrangedContentLength = get(this, 'arrangedContent.length');
				if (arrangedContentLength > pageSize * (currentPageNumber - 1)) {
					this.incrementProperty('currentPageNumber');
					this.userInteractionObserver();
				}
			},

			gotoLast: function gotoLast() {
				if (!get(this, 'gotoForwardEnabled')) {
					return;
				}
				var pageSize = get(this, 'pageSize');
				var arrangedContentLength = get(this, 'arrangedContent.length');
				var pageNumber = arrangedContentLength / pageSize;
				pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
				set(this, 'currentPageNumber', Math.ceil(pageNumber));
				this.userInteractionObserver();
			},

			gotoCustomPage: function gotoCustomPage(pageNumber) {
				set(this, 'currentPageNumber', pageNumber);
				this.userInteractionObserver();
			},

			sort: function sort(column) {
				var sortMap = {
					none: 'asc',
					asc: 'desc',
					desc: 'none'
				};
				var sortedBy = get(column, 'sortedBy') || get(column, 'propertyName');
				if (isNone(sortedBy)) {
					return;
				}
				var currentSorting = get(column, 'sorting');
				var newSorting = sortMap[currentSorting.toLowerCase()];
				var sortingArgs = [column, sortedBy, newSorting];
				if (get(this, 'multipleColumnsSorting')) {
					this._multiColumnsSorting.apply(this, sortingArgs);
				} else {
					this._singleColumnSorting.apply(this, sortingArgs);
				}
				set(this, 'currentPageNumber', 1);
				this.userInteractionObserver();
			},

			expandRow: function expandRow(index) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var multipleExpand = get(this, 'multipleExpand');
				var expandedRowIndexes = get(this, '_expandedRowIndexes');
				if (multipleExpand) {
					expandedRowIndexes.pushObject(index);
				} else {
					if (expandedRowIndexes.length === 1) {
						expandedRowIndexes.clear();
					}
					expandedRowIndexes.pushObject(index);
				}
				set(this, '_expandedRowIndexes', expandedRowIndexes);
				this.userInteractionObserver();
			},

			collapseRow: function collapseRow(index) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var expandedRowIndexes = get(this, '_expandedRowIndexes').without(index);
				set(this, '_expandedRowIndexes', expandedRowIndexes);
				this.userInteractionObserver();
			},

			/**
      * Handler for row-click
      * Toggle <code>selected</code>-state for row
      * Select only one or multiple rows depends on <code>multipleSelect</code>-value
      *
      * @param {number} index	Row index
      * @param {object} record	Model record or record ID
      */
			clickOnRow: function clickOnRow(index, record) {
				assert('row index should be numeric', typeOf(index) === 'number');
				var multipleSelect = get(this, 'multipleSelect');
				var selectedRowIndexes = get(this, '_selectedRowIndexes');
				if (selectedRowIndexes.includes(index)) {
					selectedRowIndexes = selectedRowIndexes.without(index);
					set(this, '_selectedRowIndexes', selectedRowIndexes);
				} else {
					if (multipleSelect) {
						get(this, '_selectedRowIndexes').pushObject(index);
					} else {
						if (selectedRowIndexes.length === 1) {
							get(this, '_selectedRowIndexes').clear();
						}
						get(this, '_selectedRowIndexes').pushObject(index);
					}
				}
				this.userInteractionObserver();
				if (this.get('routeName')) {
					this.get('routing').transitionTo(this.get('routeName'), [record]);
				}
			},

			selectCondition: function selectCondition(column, condition) {
				column.set('filterOperator', condition);

				this.userInteractionObserver();
			},

			setDateRange: function setDateRange(column, start, end) {
				this.set('hideFieldValue', false);
				column.set('filterString', {
					type: 'dateRange',
					columnName: column.propertyName,
					start: (0, _moment['default'])(start).startOf('day').toISOString(),
					end: (0, _moment['default'])(end).endOf('day').toISOString()
				});
			},

			cancelDatePicker: function cancelDatePicker(column) {
				column.set('filterString', '');
				this.set('hideFieldValue', true);
			},

			showDatePicker: function showDatePicker() {
				if (this.get('hideFieldValue')) {
					_ember['default'].$('.daterangepicker.dropdown-menu .calendar-table tbody td').removeClass('active end-date in-range start-date');
					_ember['default'].$('.daterangepicker.dropdown-menu .ranges .active').removeClass('active');
				}
			}
		}
	});
});