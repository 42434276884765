define('ember-common/components/models-select', ['exports', 'ember', 'ember-common/templates/components/models-select'], function (exports, _ember, _emberCommonTemplatesComponentsModelsSelect) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({

		layout: _emberCommonTemplatesComponentsModelsSelect['default'],

		tagName: 'select',

		classNameBindings: ['cssPropertyName', 'fluid', 'selection'],

		classNames: 'ui dropdown',

		selection: false,
		fluid: false,

		cssPropertyName: '',

		change: function change() {
			this.set('value', this.$('option:selected').val());
		}

	});
});