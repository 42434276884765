define('ember-common/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	// import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

	var RESTAdapter = _emberData['default'].RESTAdapter;

	//
	// /**
	//  * @class RestAdapter
	//  * @extends DS.RESTAdapter
	//  */
	exports['default'] = RESTAdapter.extend({
		authorizer: 'authorizer:token'
	});
});