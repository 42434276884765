define('ember-common/initializers/blocking-loader', ['exports', 'ember-common/services/blocking-loader'], function (exports, _emberCommonServicesBlockingLoader) {
	'use strict';

	exports['default'] = {
		name: 'loader',

		initialize: function initialize() {
			var application = arguments[1] || arguments[0];
			application.register('service:loader', _emberCommonServicesBlockingLoader['default']);

			['controller', 'component', 'route', 'router', 'helper'].forEach(function (injectionTarget) {
				application.inject(injectionTarget, 'loader', 'service:loader');
			});
		}
	};
});