define('ember-common/utils/router-setup', ['exports'], function (exports) {
	/**
  * TODO: Finish documentation
  * [description]
  * @param   {[type]} self [description]
  */
	'use strict';

	exports['default'] = function (self) {
		self.route('login');
		// self.route('callback');
		self.route('errorPage');
		// self.route('notifications', { path: 'notifications'});
		self.route('pageNotFound', { path: '/*wildcard' });
	};
});