define('ember-changeset-validations/validators/exclusion', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports['default'] = validateExclusion;

  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var isEmpty = _ember['default'].isEmpty;
  var typeOf = _ember['default'].typeOf;

  function validateExclusion() {
    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var list = options.list;
    var range = options.range;
    var allowBlank = options.allowBlank;

    return function (key, value) {
      if (allowBlank && isEmpty(value)) {
        return true;
      }

      if (list && list.indexOf(value) !== -1) {
        return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'exclusion', value, options);
      }

      if (range && range.length === 2) {
        var _range = _slicedToArray(range, 2);

        var min = _range[0];
        var max = _range[1];

        var equalType = typeOf(value) === typeOf(min) && typeOf(value) === typeOf(max);

        if (equalType && min <= value && value <= max) {
          return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'exclusion', value, options);
        }
      }

      return true;
    };
  }
});