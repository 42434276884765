define('ember-common/initializers/raven', ['exports', 'ember-common/services/raven'], function (exports, _emberCommonServicesRaven) {
	'use strict';

	exports['default'] = {
		name: 'raven',

		initialize: function initialize() {
			var application = arguments[1] || arguments[0];
			application.register('service:raven', _emberCommonServicesRaven['default']);

			['controller', 'component', 'route', 'router', 'helper', 'mixin'].forEach(function (injectionTarget) {
				application.inject(injectionTarget, 'raven', 'service:raven');
			});
			application.inject('service:store', 'raven', 'service:raven');
		}
	};
});