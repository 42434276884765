define('ember-changeset-validations/validators/confirmation', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  'use strict';

  exports['default'] = validateConfirmation;

  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var isEqual = _ember['default'].isEqual;
  var isEmpty = _ember['default'].isEmpty;

  function validateConfirmation() {
    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var on = options.on;
    var allowBlank = options.allowBlank;

    return function (key, newValue, _oldValue, changes /*, _content*/) {
      if (allowBlank && isEmpty(newValue)) {
        return true;
      }

      return isPresent(newValue) && isEqual(get(changes, on), newValue) || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'confirmation', newValue, options);
    };
  }
});