define('ember-common/components/ht-loader', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;

  /**
   * **NOT TO BE USED DIRECTLY. Use the service to interact with this.**
   * This component holds the loader.
   *
   * @class ht-loader
   * @namespace Component
   * @extends Ember.Component
   */
  exports['default'] = Component.extend({
    loader: inject.service(),

    /**
     * Reads the isProcessing property of the loader service to
     * calculate if it needs to display the loader
     *
     * @property  isProcessing
     * @type boolean
     * @private
     */
    isProcessing: computed('loader.isProcessing', function () {
      return this.get('loader.isProcessing');
    }),

    /**
     * Reads the message from the loader service and displays it
     * in the loader under the loading icon
     * @property  processingMessage
     * @type boolean
     * @private
     */
    processingMessage: computed('loader.message', function () {
      return this.get('loader.message');
    })
  });
});