define('ember-changeset-validations/validators/number', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  'use strict';

  exports['default'] = validateNumber;

  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var typeOf = _ember['default'].typeOf;
  var keys = Object.keys;

  function _isNumber(value) {
    return typeOf(value) === 'number' && !isNaN(value);
  }

  function _isInteger(value) {
    return typeOf(value) === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  function _validateType(type, opts, numValue, key) {
    var expected = opts[type];

    if (type === 'is' && numValue !== expected) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'equalTo', numValue, opts);
    } else if (type === 'lt' && numValue >= expected) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'lessThan', numValue, opts);
    } else if (type === 'lte' && numValue > expected) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'lessThanOrEqualTo', numValue, opts);
    } else if (type === 'gt' && numValue <= expected) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'greaterThan', numValue, opts);
    } else if (type === 'gte' && numValue < expected) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'greaterThanOrEqualTo', numValue, opts);
    } else if (type === 'positive' && numValue < 0) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'positive', numValue, opts);
    } else if (type === 'odd' && numValue % 2 === 0) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'odd', numValue, opts);
    } else if (type === 'even' && numValue % 2 !== 0) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'even', numValue, opts);
    } else if (type === 'multipleOf' && !_isInteger(numValue / expected)) {
      return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'multipleOf', numValue, opts);
    }

    return true;
  }
  function validateNumber() {
    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    return function (key, value) {
      if (isEmpty(value) && options.allowBlank) {
        return true;
      }

      var numValue = Number(value);

      if (isEmpty(value) || !_isNumber(numValue)) {
        return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'notANumber', value, options);
      } else if (isPresent(options.integer) && !_isInteger(numValue)) {
        return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'notAnInteger', value, options);
      }

      var optKeys = keys(options);

      for (var i = 0; i < optKeys.length; i++) {
        var type = optKeys[i];
        var m = _validateType(type, options, numValue, key);

        if (typeof m === 'string') {
          return m;
        }
      }

      return true;
    };
  }
});