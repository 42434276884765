define('ember-common/instance-initializers/application-route', ['exports'], function (exports) {
	// import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
	// import Ember from 'ember';

	'use strict';

	exports['default'] = {
		name: 'application-route',
		initialize: function initialize(applicationInstance) {
			if (applicationInstance && typeof applicationInstance.lookup === 'function') {
				(function () {
					var applicationRoute = applicationInstance.lookup('route:application');
					var intlService = applicationInstance.lookup('service:intl');
					if (applicationRoute && typeof applicationRoute.reopen === 'function') {
						applicationRoute.reopen({
							beforeModel: function beforeModel() {
								return intlService.setLocale('en-us');
							},
							activate: function activate() {
								this.notifications.setDefaultAutoClear(true);
							},
							actions: {
								showModal: function showModal(name) {
									$('.ui.' + name + '.modal').modal('show');
									// $('body').removeClass('undetached');
								},
								set: function set(property, value, context) {
									context.set(property, value);
								}
							}
						});
					}
				})();
			}
		}
	};
});