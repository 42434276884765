define("ember-common/utils/assign-poly", ["exports"], function (exports) {
	"use strict";

	exports["default"] = assignPoly;

	function assignPoly(original) {
		for (var i = 1; i < arguments.length; i++) {
			var arg = arguments[i];
			if (!arg) {
				continue;
			}

			var updates = Object.keys(arg);

			for (var _i = 0; _i < updates.length; _i++) {
				var prop = updates[_i];
				original[prop] = arg[prop];
			}
		}

		return original;
	}
});