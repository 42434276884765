define('ember-common/initializers/inject-notifications', ['exports'], function (exports) {
	'use strict';

	exports['default'] = {
		name: 'inject-notifications',

		initialize: function initialize() {
			var application = arguments[1] || arguments[0];

			['controller', 'component', 'route', 'router', 'helper', 'mixin'].forEach(function (injectionTarget) {
				application.inject(injectionTarget, 'notifications', 'service:notification-messages');
			});
		}
	};
});