define('ember-common/initializers/token-data', ['exports', 'ember-common/services/token-data'], function (exports, _emberCommonServicesTokenData) {
	'use strict';

	exports['default'] = {
		name: 'token',

		initialize: function initialize() {
			var application = arguments[1] || arguments[0];
			application.register('service:token', _emberCommonServicesTokenData['default']);

			['controller', 'component', 'route', 'router', 'helper', 'mixin'].forEach(function (injectionTarget) {
				application.inject(injectionTarget, 'token', 'service:token');
			});
			application.inject('service:store', 'token', 'service:token');
		}
	};
});