define('ember-common/components/date-range-picker', ['exports', 'ember', 'moment', 'ember-common/templates/components/date-range-picker'], function (exports, _ember, _moment, _emberCommonTemplatesComponentsDateRangePicker) {
	'use strict';

	var run = _ember['default'].run;
	var isEmpty = _ember['default'].isEmpty;
	var computed = _ember['default'].computed;

	var noop = function noop() {};

	exports['default'] = _ember['default'].Component.extend({
		layout: _emberCommonTemplatesComponentsDateRangePicker['default'],
		classNameBindings: ['containerClass'],
		attributeBindings: ['start', 'end', 'serverFormat'],
		start: undefined,
		end: undefined,
		minDate: undefined,
		maxDate: undefined,
		timePicker: false,
		timePicker24Hour: false,
		timePickerSeconds: false,
		timePickerIncrement: undefined,
		showWeekNumbers: false,
		showDropdowns: false,
		linkedCalendars: false,
		hideFieldValue: true,
		datelimit: false,
		parentEl: 'body',
		format: 'MMM D, YYYY',
		serverFormat: 'YYYY-MM-DD',
		rangeText: computed('start', 'end', function () {
			var format = this.get('format');
			var serverFormat = this.get('serverFormat');
			var start = this.get('start');
			var end = this.get('end');
			if (!isEmpty(start) && !isEmpty(end)) {
				return (0, _moment['default'])(start, serverFormat).format(format) + this.get('separator') + (0, _moment['default'])(end, serverFormat).format(format);
			}
			return '';
		}),
		opens: null,
		drops: null,
		separator: ' - ',
		singleDatePicker: false,
		placeholder: null,
		containerClass: 'form-group',
		inputClass: 'form-control',
		inputClasses: computed('inputClass', function () {
			var inputClass = this.get('inputClass');
			return inputClass ? 'daterangepicker-input ' + inputClass : 'daterangepicker-input';
		}),
		buttonClasses: ['btn'],
		applyClass: null,
		cancelClass: null,
		ranges: {
			'Today': [(0, _moment['default'])().startOf('day'), (0, _moment['default'])().endOf('day')],
			'Yesterday': [(0, _moment['default'])().subtract(1, 'days').startOf('day'), (0, _moment['default'])().subtract(1, 'days').endOf('day')],
			'Last 7 Days': [(0, _moment['default'])().subtract(7, 'days'), (0, _moment['default'])()],
			'Last 30 Days': [(0, _moment['default'])().subtract(30, 'days'), (0, _moment['default'])()],
			'This Month': [(0, _moment['default'])().startOf('month'), (0, _moment['default'])().endOf('month')],
			'Last Month': [(0, _moment['default'])().subtract(1, 'month').startOf('month'), (0, _moment['default'])().subtract(1, 'month').endOf('month')]
		},
		daysOfWeek: _moment['default'].weekdaysMin(),
		monthNames: _moment['default'].monthsShort(),
		removeDropdownOnDestroy: false,
		cancelLabel: 'Cancel',
		applyLabel: 'Apply',
		customRangeLabel: 'Custom Range',
		showCustomRangeLabel: false,
		fromLabel: 'From',
		toLabel: 'To',
		hideAction: null,
		applyAction: null,
		cancelAction: null,
		showAction: null,
		applyOnHide: false,
		autoUpdateInput: true,
		autoApply: false,
		alwaysShowCalendars: false,
		context: undefined,
		firstDay: 0,
		isInvalidDate: noop,
		isCustomDate: noop,
		// Init the dropdown when the component is added to the DOM
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.setupPicker();
			_ember['default'].run.later(function () {
				_ember['default'].$('.daterangepicker').hide();
			}, 10);
		},
		didRender: function didRender() {
			_ember['default'].run.later(function () {
				_ember['default'].$('.daterangepicker').hide();
			}, 10);
			this.setOnchangeEvent();
		},
		setOnchangeEvent: function setOnchangeEvent() {
			var input = _ember['default'].$('.daterangepicker-input');
			input.change(function () {
				_ember['default'].$('.daterangepicker').hide();
			});
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
			this.setupPicker();
		},
		// Remove the hidden dropdown when this component is destroyed
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			run.cancel(this._setupTimer);
			if (this.get('removeDropdownOnDestroy')) {
				_ember['default'].$('.daterangepicker').hide();
			}

			_ember['default'].run.later(function () {
				_ember['default'].$('.daterangepicker').hide();
			}, 100);
		},
		didDestroyElement: function didDestroyElement() {
			this._super.apply(this, arguments);

			_ember['default'].run.later(function () {
				_ember['default'].$('.daterangepicker').hide();
			}, 100);
		},
		getOptions: function getOptions() {
			var momentStartDate = (0, _moment['default'])(this.get('start'), this.get('serverFormat'));
			var momentEndDate = (0, _moment['default'])(this.get('end'), this.get('serverFormat'));
			var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
			var endDate = momentEndDate.isValid() ? momentEndDate : undefined;
			var momentMinDate = (0, _moment['default'])(this.get('minDate'), this.get('serverFormat'));
			var momentMaxDate = (0, _moment['default'])(this.get('maxDate'), this.get('serverFormat'));
			var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
			var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;
			var showCustomRangeLabel = this.get('showCustomRangeLabel');
			var options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'autoUpdateInput', 'autoApply', 'applyOnHide', 'timePicker', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'showCustomRangeLabel', 'linkedCalendars', 'dateLimit', 'parentEl');
			var localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'fromLabel', 'toLabel', 'format', 'firstDay', 'daysOfWeek', 'monthNames', 'separator');
			var defaultOptions = {
				locale: localeOptions,
				showCustomRangeLabel: showCustomRangeLabel,
				startDate: startDate,
				endDate: endDate,
				minDate: minDate,
				maxDate: maxDate
			};
			if (!this.get('singleDatePicker')) {
				options.ranges = this.get('ranges');
			}
			return Object.assign({}, options, defaultOptions);
		},
		setupPicker: function setupPicker() {
			run.cancel(this._setupTimer);
			this._setupTimer = run.scheduleOnce('afterRender', this, this._setupPicker);
		},
		_setupPicker: function _setupPicker() {
			this.$('.daterangepicker-input').daterangepicker(this.getOptions());
			this.attachPickerEvents();
		},
		attachPickerEvents: function attachPickerEvents() {
			var _this = this;

			this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
				_this.handleDateRangePickerEvent('applyAction', picker);
			});
			this.$('.daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
				_ember['default'].run.next(function () {
					return _this.handleDateRangePickerEvent('hideAction', picker, !_this.get('applyOnHide'));
				});
			});
			this.$('.daterangepicker-input').on('cancel.daterangepicker', function (ev, picker) {
				_this.handleDateRangePickerEvent('cancelAction', picker, true);
			});
			this.$('.daterangepicker-input').on('show.daterangepicker', function (ev, picker) {
				_this.handleDateRangePickerEvent('showAction', picker);
			});
		},
		handleDateRangePickerEvent: function handleDateRangePickerEvent(actionName, picker) {
			var isCancel = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

			var start = undefined;
			var end = undefined;

			if (!isCancel) {
				start = picker.startDate.format(this.get('serverFormat'));
				end = picker.endDate.format(this.get('serverFormat'));
			}

			if (this.get(actionName)) {
				_ember['default'].assert(actionName + ' for date-range-picker must be a function', typeof this.get(actionName) === 'function');
				this.sendAction(actionName, start, end, picker);
			} else {
				if (!this.isDestroyed) {
					this.setProperties({ start: start, end: end });
				}
			}
		}
	});
});