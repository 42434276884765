define('ember-common/services/blocking-loader', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var Service = _ember['default'].Service;

	exports['default'] = Service.extend({
		isProcessing: false,
		message: null,
		init: function init() {
			this._super.init && this._super.init.apply(this, arguments);
		},
		show: function show(message) {
			this.set('isProcessing', true);
			this.set('message', message);
		},
		clear: function clear() {
			this.set('isProcessing', false);
			this.set('message', null);
		}
	});
});