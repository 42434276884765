define('mock-socket/helpers/logger', ['exports'], function (exports) {
  'use strict';

  exports['default'] = log;

  function log(method, message) {
    /* eslint-disable no-console */
    if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'test') {
      console[method].call(null, message);
    }
    /* eslint-enable no-console */
  }
});