define('ember-common/components/login-form', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var Component = _ember['default'].Component;
	var inject = _ember['default'].inject;
	var computed = _ember['default'].computed;

	exports['default'] = Component.extend({
		tagName: 'form',
		attributeBindings: ['onsubmit'],
		onsubmit: 'return false',
		session: inject.service(),
		isProcessing: false,
		disableLogin: computed('identification', 'password', function () {
			return !this.get('identification') && !this.get('password');
		})
	});
});